import { IFlowNodeBaseTypeData } from '@/interfaces/flows.interfaces';
import {
  mdiDatabaseSearchOutline,
  mdiFingerprint,
  mdiLightbulbOutline,
  mdiLinkVariant,
  mdiMemory,
  mdiSitemapOutline,
  mdiRocketLaunchOutline,
  mdiRoutes,
  mdiText,
  mdiVectorPolyline,
  mdiWrenchOutline,
  mdiFileSearchOutline,
  mdiCheckboxMultipleMarked,
} from '@mdi/js';
import { TEST_IDS } from './tests';

export enum FLOW_NODE_PARAM_TYPE {
  BOOLEAN = 'boolean',
  TEXT = 'text',
  NUMBER = 'number',
  LIST = 'list',
  LIST_FREE = 'list_free',
  CODE_EDITOR = 'code_editor',
  KNOWLEDGEBASE = 'knowledgebase',
  KAI_KNOWLEDGEBASE = 'kai_knowledgebase',
  LIB_PROMPT = 'lib_prompt',
  NODE = 'node',
  KEY_VALUE = 'key_value',
  KEY_VALUE_NESTED = 'key_value_nested',
}

export enum FLOW_NODE_BASE_TYPE {
  LLM = 'llm',
  AGENT = 'agent',
  TOOL = 'tool',
  CHAIN = 'chain',
  MEMORY = 'memory',
  PROMPT = 'prompt',
  EMBEDDING = 'embedding',
  RETRIEVER = 'retriever',
  // LOADER = 'loader',
}

export enum FLOW_NODE_TYPE {
  // Agents
  ZeroShotAgent = 'ZeroShotAgent',
  AgentInitializer = 'AgentInitializer',

  // Chains
  LLMChain = 'LLMChain',
  CombineDocsChain = 'CombineDocsChain',
  RetrievalQAWithSourcesChain = 'RetrievalQAWithSourcesChain',
  RetrievalQAChain = 'RetrievalQAChain',
  FilterChain = 'FilterChain',
  LLMFilterChain = 'LLMFilterChain',
  ApiRequestChain = 'ApiRequestChain',
  ExtractionChain = 'ExtractionChain',
  ConcurrentChain = 'ConcurrentChain',
  OutputFilterChain = 'OutputFilterChain',
  ConditionalRouterChain = 'ConditionalRouterChain',
  KnowledgebaseRetrievalChain = 'KnowledgebaseRetrievalChain',
  ConversationFabricChain = 'ConversationFabricChain',
  FlowSelectionChain = 'FlowSelectionChain',
  ConversationCloudChain = 'ConversationCloudChain',

  // LLMs
  LPLLM = 'LPLLM',
  LPLLMGateway = 'LPLLMGateway',
  OpenAILLM = 'OpenAILLM',
  ChatOpenAILLM = 'ChatOpenAILLM',

  // Memory
  ConversationBufferMemory = 'ConversationBufferMemory',
  LPFirestoreConversationMemory = 'LPFirestoreConversationMemory',
  LPContextualMemory = 'LPContextualMemory',

  // Embeddings
  OpenAIEmbeddings = 'OpenAIEmbeddings',

  // Retrievers
  PineconeVectorStore = 'PineconeVectorStore',
  LPKnowledgebaseStore = 'LPKnowledgebaseStore',
  DynamicKAIKnowledgeStore = 'DynamicKAIKnowledgeStore',
  GoogleSearchRetriever = 'GoogleSearchRetriever',
  QueryProcessor = 'QueryProcessor',

  // Prompts
  LPPrompt = 'LPPrompt',
  TemplatePrompt = 'TemplatePrompt',

  // Tools
  HumanInputTool = 'HumanInputTool',
  CalculatorTool = 'CalculatorTool',
  FaqToolNode = 'FaqToolNode',
  ApiToolNode = 'ApiToolNode',
  GenericChainTool = 'GenericChainTool',
}

export enum FLOW_JOB_TYPE {
  AIS_SIMULATION = 'ais-simulation',
  INTENT_OPT = 'intent-opt',
  AIS_ROUTE_GEN = 'ais-route-gen',
}

export enum FLOW_JOB_EXECUTION_STATE {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  CANCELLING = 'CANCELLING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
}

export enum FLOW_NODE_TEMPLATE_GROUP {
  OFFICIAL = 'official',
  COMMUNITY = 'community',
}

export enum FLOW_USE_CASE {
  DATA_COLLECTION = 'data_collection',
  GUIDED_ROUTING = 'guided_routing',
  GUIDED_INFO = 'guided_info',
  CONVERSATIONAL_FABRIC = 'conversational_fabric',
  CONVERSATIONAL_ANALYSIS = 'conversational_analysis',
}

export enum FLOW_GUIDANCE {
  STRICT = 'strict',
  BALANCED = 'balanced',
}

export const FLOW_USE_CASE_DATA: {
  [p in FLOW_USE_CASE]: { title: string; icon: string; description: string };
} = {
  [FLOW_USE_CASE.DATA_COLLECTION]: {
    title: 'Data Collection',
    description: 'Capture a set of data points and convert to desired formats',
    icon: mdiDatabaseSearchOutline,
  },
  [FLOW_USE_CASE.GUIDED_ROUTING]: {
    title: 'Guided Routing',
    description: 'Route consumer to the most relevant agent or skill',
    icon: mdiRoutes,
  },
  [FLOW_USE_CASE.GUIDED_INFO]: {
    title: 'Information Routing',
    description:
      'Route consumer to the most relevant knowledge article and agent',
    icon: mdiFileSearchOutline,
  },
  [FLOW_USE_CASE.CONVERSATIONAL_FABRIC]: {
    title: 'Conversational Fabric',
    description:
      'Stitch multiple flows together and synthesize a final response',
    icon: mdiSitemapOutline,
  },
  [FLOW_USE_CASE.CONVERSATIONAL_ANALYSIS]: {
    title: 'Conversational Analysis',
    description:
      'Analyze the conversation and determine which defined steps have been satisfied',
    icon: mdiCheckboxMultipleMarked,
  },
};

export const FLOW_NODE_BASE_TYPE_DATA: {
  [p in FLOW_NODE_BASE_TYPE]: Omit<IFlowNodeBaseTypeData, 'type'>;
} = {
  [FLOW_NODE_BASE_TYPE.AGENT]: {
    title: 'Agent',
    icon: mdiRocketLaunchOutline,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupAgents,
  },
  [FLOW_NODE_BASE_TYPE.CHAIN]: {
    title: 'Chain',
    icon: mdiLinkVariant,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupChains,
  },
  // [FLOW_NODE_BASE_TYPE.LOADER]: {
  //   title: 'Loader',
  //   icon: mdiPaperclip,
  //   isPlural: true,
  // },
  [FLOW_NODE_BASE_TYPE.LLM]: {
    title: 'LLM',
    icon: mdiLightbulbOutline,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupLLMs,
  },
  [FLOW_NODE_BASE_TYPE.EMBEDDING]: {
    title: 'Embedding',
    icon: mdiFingerprint,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupEmbeddings,
  },
  [FLOW_NODE_BASE_TYPE.MEMORY]: {
    title: 'Memory',
    icon: mdiMemory,
    isPlural: false,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupMemory,
  },
  [FLOW_NODE_BASE_TYPE.PROMPT]: {
    title: 'Prompt',
    icon: mdiText,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupPrompts,
  },
  [FLOW_NODE_BASE_TYPE.TOOL]: {
    title: 'Tool',
    icon: mdiWrenchOutline,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupTools,
  },
  [FLOW_NODE_BASE_TYPE.RETRIEVER]: {
    title: 'Retriever',
    icon: mdiVectorPolyline,
    isPlural: true,
    testid: TEST_IDS.settingsFlows.nodeTemplatesGroupRetrievers,
  },
};

export const FLOW_NODE_BASE_TYPES = Object.entries(
  FLOW_NODE_BASE_TYPE_DATA,
).map(([type, val]) => ({ ...val, type } as IFlowNodeBaseTypeData));

export const FLOW_NODE_OUTPUT_KEY = 'output';

export enum DEBUG_ACTION_TYPE {
  LLM_START = 'llm_start',
  LLM_END = 'llm_end',
  LLM_NEW_TOKEN = 'llm_new_token',
  LLM_ERROR = 'llm_error',
  CHAIN_START = 'chain_start',
  CHAIN_END = 'chain_end',
  CHAIN_ERROR = 'chain_error',
  TOOL_START = 'tool_start',
  TOOL_END = 'tool_end',
  TOOL_ERROR = 'tool_error',
  AGENT_ACTION = 'agent_action',
  AGENT_FINISH = 'agent_finish',
  ON_TEXT = 'on_text',
}

export const FLOW_NODE_PARAM_ORDER: Partial<
  Record<FLOW_NODE_PARAM_TYPE, number>
> = {
  [FLOW_NODE_PARAM_TYPE.NODE]: 1,
};

export enum FLOW_FILTER_TYPE {
  MY_FLOWS = 'MY_FLOWS',
  ALL_FLOWS = 'ALL_FLOWS',
  FLOW_TEMPLATES = 'FLOW_TEMPLATES',
  FLOW_USE_CASES = 'FLOW_USE_CASES',
}

export const FLOW_FILTER_ITEMS = [
  { type: 'item', title: 'All Flows', value: FLOW_FILTER_TYPE.ALL_FLOWS },
  { type: 'item', title: 'My Flows', value: FLOW_FILTER_TYPE.MY_FLOWS },
  {
    type: 'item',
    title: 'Templates',
    value: FLOW_FILTER_TYPE.FLOW_TEMPLATES,
  },
  {
    type: 'item',
    title: 'Use Cases',
    value: FLOW_FILTER_TYPE.FLOW_USE_CASES,
  },
];

export enum FLOW_USE_CASE_SECTIONS {
  GROUPS = 'GROUPS',
  ROUTES = 'ROUTES',
  KNOWLEDGE = 'KNOWLEDGE',
  FLOWS = 'FLOWS',
}

export const FLOW_USE_CASE_ROUTING_DEFAULT_GROUP = 'DEFAULT';

export enum FLOWS_VERSION {
  V1 = 'v1',
  V2 = 'v2',
}

export enum FLOWS_FILTER_KEY {
  FLOW_OWNER = 'created_by',
  FLOW_TYPE = 'flow_type',
  ACCOUNT_ID = 'account_id',
  KEYWORD = 'keyword',
}

export enum FLOW_TYPE {
  TEMPLATES = 'templates',
  USE_CASES = 'use_cases',
}
