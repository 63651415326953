import { AppEnvVar, IColor } from '@/interfaces/interfaces';

export enum SPEAKER {
  agent = 'Agent',
  consumer = 'Consumer',
  system = 'System',
}

export enum PERMISSIONS {
  CONV_CLOUD_SUMMARY = 'CONV_CLOUD_SUMMARY',
  CONV_UPLOAD = 'CONV_UPLOAD',
  BATCH_SUMMARIES = 'BATCH_SUMMARIES',
  CONVERSATIONS_VIEW_OTHERS = 'CONVERSATIONS_VIEW_OTHERS',
  TRANSCRIPT_ANALYZER = 'TRANSCRIPT_ANALYZER',
}

export enum LOGIN_METHOD {
  FIREBASE_AUTH = 'FB',
  CONVERSATIONAL_CLOUD_SSO = 'CC',
}

export enum STORAGE_KEYS {
  AUTO_CONNECT = '__flows_autoConnect__',
  THEME = '__theme__',
  LOGIN_METHOD = '__login_loginMethod__',
  REMEMBER_ACCOUNT_ID = '__login_rememberAccountId__',
  ACCOUNT_ID = '__login_accountId__',
  CONV_NAVIGATOR_TAB = '__conv_navigator_tab__',
  LOGIN_REDIRECT = '__login_redirect__',
  AGENT_WIDGET_DEBUG = '__ais_agent_widget_debug__',
  SHOW_DEBUG_VARIABLES = '__ais_show_debug_variables__',
}

export enum MODEL_TYPE {
  GENERIC_SIMPLE = 'generic_simple',
  LANGCHAIN_FLOW = 'langchain_flow',
  LANGCHAIN_ACTION_AGENT = 'langchain_action_agent',
  CONVERSATION_CLOUD = 'conversation_cloud',

  // DEPRECATED
  AIRLINE_QA = 'airline_qa',
  ALADDIN_QA = 'aladdin_qa',
  BLACKBOX = 'blackbox',
  GRAYBOX = 'graybox',
  MIX_QA = 'mix_qa',
  WILDHEALTH = 'wildhealth',
  THRIVE_HAWAII = 'thrive_hawaii',
  INSTACART = 'instacart',
  GENERIC_LANGCHAIN = 'generic_langchain',
  LANGCHAIN_AGENT = 'langchain_agent',
  LANGCHAIN_CHAIN = 'langchain_chain',
}

export const MODEL_TYPE_LABELS: Partial<Record<MODEL_TYPE, string>> = {
  [MODEL_TYPE.GENERIC_SIMPLE]: 'Generic Simple',
  [MODEL_TYPE.LANGCHAIN_FLOW]: 'aiStudio Flow',
  [MODEL_TYPE.LANGCHAIN_ACTION_AGENT]: 'LangChain Action Agent',
  [MODEL_TYPE.CONVERSATION_CLOUD]: 'Conversational Cloud',
};

export enum MODEL_QUERY_TYPE {
  COMPLETION = 'COMPLETION',
  CHAT = 'CHAT',
}

export const MODEL_QUERY_TYPES = Object.entries(MODEL_QUERY_TYPE).map(
  ([, val]) => val,
);

export const LP_TRACE_ID = 'X-Request-ID';

export const DEFAULT_USER_ID = 'NO_USER_ID';
export const DEFAULT_ACCOUNT_ID = 'NO_ACCOUNT_ID';
export const DEFAULT_THEME = 'dark';

export const DEFAULT_PROMPT_MESSAGES_COUNT = 10;
export const MAX_WORD_LIMIT = 500;
export const MAX_MODEL_DESC = 210;

export const V1 = `v1`;
export const V2 = `v2`;

export const API_ROUTES = {
  USERS: (v = V1) => `api/${v}/users`,
  USERS_DETAILS: (v = V1) => `${API_ROUTES.USERS(v)}_details`,
  USERS_SELF: (v = V1) => API_ROUTES.USERS_BY_ID('self', v),
  USERS_BY_ID: (id: string, v = V1) => `${API_ROUTES.USERS(v)}/${id}`,
  USERS_TERMS_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.USERS_BY_ID(id, v)}/terms_agree`,
  USERS_MODELS_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.USERS_BY_ID(id, v)}/models`,
  FEEDBACK: (v = V1) => `api/${v}/feedback`,
  SUMMARY: (v = V1) => `api/${v}/summary`,
  SUMMARY_BATCH: (v = V1) => `${API_ROUTES.SUMMARY(v)}/batch`,
  SUMMARY_BATCH_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.SUMMARY(v)}/batch/${id}`,
  QUERY: (v = V1) => `api/${v}/query`,
  CONVERSATIONS: (v = V1) => `api/${v}/conversations`,
  CONVERSATIONS_EXPORT: (v = V1) => `${API_ROUTES.CONVERSATIONS(v)}/export`,
  CONVERSATIONS_UPLOAD: (v = V1) => `${API_ROUTES.CONVERSATIONS(v)}/upload`,
  CONVERSATIONS_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.CONVERSATIONS(v)}/${id}`,
  GET_CONV_CLOUD_OPEN_CONVERSATIONS: (v = V1) =>
    `${API_ROUTES.CONVERSATIONS(v)}/open_conv_cloud`,
  CLOSE_CONV_CLOUD_CONVERSATION: (convId: string, v = V1) =>
    `${API_ROUTES.CONVERSATIONS(v)}/${convId}/close`,
  SUGGESTIONS: (convId: string, v = V1) =>
    `${API_ROUTES.CONVERSATIONS_BY_ID(convId, v)}/suggestions`,
  SUGGESTIONS_BY_ID: (convId: string, id: string, v = V1) =>
    `${API_ROUTES.SUGGESTIONS(convId, v)}/${id}`,
  FEEDBACK_BY_ID: (convId: string, v = V1) =>
    `${API_ROUTES.CONVERSATIONS_BY_ID(convId, v)}/feedback`,
  SIMULATIONS: (v = V1) => `api/${v}/simulations`,
  SIMULATIONS_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.SIMULATIONS(v)}/${id}`,
  SIMULATION_STATUS: (id: string, v = V1) =>
    `${API_ROUTES.SIMULATIONS_BY_ID(id, v)}/status`,
  SIMULATION_RESULTS: (id: string, jobId: string, v = V1) =>
    `${API_ROUTES.SIMULATIONS_BY_ID(id, v)}/jobs/${jobId}`,
  CATEGORIES: (v = V1) => `api/${v}/categories`,
  CATEGORIES_BY_ID: (id: string, v = V1) => `${API_ROUTES.CATEGORIES(v)}/${id}`,
  TRANSCRIPT_ANALYSIS: (v = V1) => `api/${v}/transcript_analysis`,
  TRANSCRIPT_ANALYSIS_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.TRANSCRIPT_ANALYSIS(v)}/${id}`,
  KNOWLEDGEBASES: (v = V1) => `api/${v}/knowledgebases`,
  KNOWLEDGEBASES_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.KNOWLEDGEBASES(v)}/${id}`,
  KNOWLEDGEBASES_HEALTH_BY_ID: (id: string, v = V1) =>
    `${API_ROUTES.KNOWLEDGEBASES(v)}/${id}/calculate_health`,
  KNOWLEDGEBASES_FILE_BY_ID: (id: string, source: string, v = V1) =>
    `${API_ROUTES.KNOWLEDGEBASES_BY_ID(id, v)}/files/${source}`,
  KNOWLEDGEBASES_ITEMS_BY_SOURCE_ID: (id: string, sourceId: string, v = V1) =>
    `${API_ROUTES.KNOWLEDGEBASES_BY_ID(id, v)}/items/${sourceId}`,
  KAI_KNOWLEDGEBASES_BY_ACCOUNT_ID: (accountId: string, v = V1) =>
    `${API_ROUTES.KNOWLEDGEBASES(v)}/kai/${accountId}`,
  PROMPT_LIBRARY_ACCOUNT: (accountId: string, v = V1) =>
    `api/${v}/account/${accountId}/prompt_library`,
  PROMPT_LIBRARY_LLM_PROVIDERS: (accountId: string, v = V1) =>
    `${API_ROUTES.PROMPT_LIBRARY_ACCOUNT(accountId, v)}/providers`,
  PROMPT_LIBRARY_CREATE_PROMPT: (accountId: string, v = V1) =>
    `${API_ROUTES.PROMPT_LIBRARY_ACCOUNT(accountId, v)}`,
  PROMPT_LIBRARY_UPDATE_PROMPT: (accountId: string, promptId: string, v = V1) =>
    `${API_ROUTES.PROMPT_LIBRARY_ACCOUNT(accountId, v)}/${promptId}`,
  PROMPT_LIBRARY_SYSTEM: (v = V1) => `api/${v}/prompt_library`,
  MODELS: (v = V1) => `api/${v}/models`,
  MODELS_DETAILS: (v = V1) => `${API_ROUTES.MODELS(v)}_details`,
  MODELS_BY_ID: (id: string, v = V1) => `${API_ROUTES.MODELS(v)}/${id}`,
  MODELS_BY_TYPE_ID: (type: string, id: string, v = V1) =>
    `${API_ROUTES.MODELS(v)}/${type}/${id}`,
  KNOWLEDGEBASE: (v = V1) => `api/${v}/knowledge_base`,
  KB_UPLOAD_STATUS: (v = V1) => `api/${v}/knowledge_base/upload_status`,
  PROCESSORS: (processor: PROCESSORS, id: string, v = V1) =>
    `api/${v}/processors/${processor}/models/${id}`,
  CONNECTORS: (v = V1) => `api/${v}/connectors`,
  CONNECTORS_BY_ID: (id: string, v = V1) => `${API_ROUTES.CONNECTORS(v)}/${id}`,
  GENERATE_QUESTION_KB_RANDOM: (v = V1) =>
    `api/${v}/generators/question_answer/knowledgebase`,
  GENERATE_QUESTION_SIM: (simulationId: string, v = V1) =>
    `api/${v}/generator/question_answer/simulation/${simulationId}`,
  QUESTIONS_GENERATOR_CONV_CLOUD: (v = V1) =>
    `api/${v}/generators/question_answer/conversation_cloud`,
  GET_GENERATE_KAI_ROUTES_STATUS: (v = V1) =>
    `api/${v}/generators/routes/kai/status`,
  GENERATE_KAI_ROUTES_LLM: (v = V1) => `api/${v}/generators/routes/kai_llm`,
  GENERATE_KAI_ROUTES_NON_LLM: (v = V1) =>
    `api/${v}/generators/routes/kai_non_llm`,
  RESOLUTION_EVAL: (v = V1) => `api/${v}/evaluators/resolution_classifier`,
  GUIDED_ROUTING_EVAL: (v = V1) => `api/${v}/evaluators/guided_routing_flow`,
  FLOWS: (v = V2) => `api/${v}/flows`,
  FLOWS_BY_ID: (id: string, v = V2) => `${API_ROUTES.FLOWS(v)}/${id}`,
  FLOWS_NODE_TEMPLATES: (v = V2) => `${API_ROUTES.FLOWS(v)}/node_templates`,
  FLOWS_VALIDATE: (v = V2) => `${API_ROUTES.FLOWS(v)}/validate`,
  FLOWS_RESPONSE: (v = V2) => `${API_ROUTES.FLOWS(v)}/response`,
  SSO_LOGIN: (accountId: string, v = V1) => `api/${v}/sso/login/${accountId}`,
  SSO_LOGOUT: (accountId: string, v = V1) => `api/${v}/sso/logout/${accountId}`,
};

export enum LD_CLIENT {
  QA = '63c01bd007a29f12f1ebd298',
  ALPHA = '63c01bd007a29f12f1ebd290',
  PROD_AU = '63c01bd007a29f12f1ebd294',
  PROD_EU = '63c01bd007a29f12f1ebd296',
  PROD_US = '63c01bd007a29f12f1ebd29a',
}

export enum LP_ENV {
  LOCAL = 'local',
  QA = 'QA',
  ALPHA = 'Alpha',
  PROD_US = 'PROD_US',
  GCP_QA = 'GCP_QA',
  GCP_ALPHA = 'GCP_Alpha',
  GCP_PROD_AU = 'GCP_Prod_AU',
  GCP_PROD_EU = 'GCP_Prod_EU',
  GCP_PROD_US = 'GCP_Prod_US',
}

export enum LP_REGION {
  US = 'US',
  EU = 'EU',
  AU = 'AU',
}

export const FIREBASE_CONF = {
  DEV: {
    apiKey: 'AIzaSyAumlG3483z1KrO4P5IbT3ic_K0wV8GcUc',
    authDomain: 'lpgprj-sbo-n-datasci-gl-02.firebaseapp.com',
    projectId: 'lpgprj-sbo-n-datasci-gl-02',
    storageBucket: 'lpgprj-sbo-n-datasci-gl-02.appspot.com',
    messagingSenderId: '463853792772',
    appId: '1:463853792772:web:bc703c564b5e861149cf51',
  },
  ALPHA: {
    apiKey: 'AIzaSyDlGPsYgKJVVuJHWc21-6-4YGqqjuA7x-M',
    authDomain: 'lpgprj-sbo-n-datadv-gl-01.firebaseapp.com',
    projectId: 'lpgprj-sbo-n-datadv-gl-01',
    storageBucket: 'lpgprj-sbo-n-datadv-gl-01.appspot.com',
    messagingSenderId: '614586989415',
    appId: '1:614586989415:web:7e0675ee0f6d6f18dccdc2',
    measurementId: 'G-E8L1EW5T2D',
  },
  PROD_US: {
    apiKey: 'AIzaSyB8Bqouh_WWkCB4GsFJPdD92SGuwwt3cQQ',
    authDomain: 'lpgprj-sbo-n-datadv-gl-02.firebaseapp.com',
    projectId: 'lpgprj-sbo-n-datadv-gl-02',
    storageBucket: 'lpgprj-sbo-n-datadv-gl-02.appspot.com',
    messagingSenderId: '1002813630897',
    appId: '1:1002813630897:web:b3e2776a8a77c0f366b590',
    measurementId: 'G-RDH6RLHK69',
  },
  GCP_QA: {
    apiKey: 'AIzaSyAjtYxt5jYDbEAt-JJuFkmLyach6Q0aP6c',
    authDomain: 'lpgprj-b2b-q-aap-us-1.firebaseapp.com',
    projectId: 'lpgprj-b2b-q-aap-us-1',
    storageBucket: 'lpgprj-b2b-q-aap-us-1.appspot.com',
    messagingSenderId: '72903252723',
    appId: '1:72903252723:web:9ba870bcebb87b5d5a7586',
  },
  GCP_ALPHA: {
    apiKey: 'AIzaSyBeiyPfdlFGk_w-qMxKAd2EKBdunOorg1A',
    authDomain: 'lpgprj-b2b-a-aap-us-1.firebaseapp.com',
    projectId: 'lpgprj-b2b-a-aap-us-1',
    storageBucket: 'lpgprj-b2b-a-aap-us-1.appspot.com',
    messagingSenderId: '522736805837',
    appId: '1:522736805837:web:207b633717264f35e5c684',
  },
  GCP_PROD_AU: {
    apiKey: 'AIzaSyB8QSrvyxiN-wuykI9zl05D7WLf6UIpBIw',
    authDomain: 'lpgprj-b2b-p-aap-au-1.firebaseapp.com',
    projectId: 'lpgprj-b2b-p-aap-au-1',
    storageBucket: 'lpgprj-b2b-p-aap-au-1.appspot.com',
    messagingSenderId: '927664985700',
    appId: '1:927664985700:web:091c7008f7df8b57c3b171',
  },
  GCP_PROD_EU: {
    apiKey: 'AIzaSyDrQTeXwXIpncPEWt99pJ2IEa6VZE4bU10',
    authDomain: 'lpgprj-b2b-p-aap-eu-1.firebaseapp.com',
    projectId: 'lpgprj-b2b-p-aap-eu-1',
    storageBucket: 'lpgprj-b2b-p-aap-eu-1.appspot.com',
    messagingSenderId: '820532484897',
    appId: '1:820532484897:web:588590880406bf8478bc60',
  },
  GCP_PROD_US: {
    apiKey: 'AIzaSyBlEuIp23w9aHEwedty7kiKk0Rp0XdrL3k',
    authDomain: 'lpgprj-b2b-p-aap-us-1.firebaseapp.com',
    projectId: 'lpgprj-b2b-p-aap-us-1',
    storageBucket: 'lpgprj-b2b-p-aap-us-1.appspot.com',
    messagingSenderId: '730240864749',
    appId: '1:730240864749:web:6424282772d34abdee97e4',
  },
};

export const COMPLETIONS_DEFAULTS = {
  OPENAI_GATEWAY_MODEL_NAME: 'gpt-3.5-turbo',
  OPENAI_GATEWAY_MODEL_NAME_LIST: [
    'gpt-4',
    'gpt-4-0613',
    'gpt-4-1106-preview',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-0613',
    'gpt-3.5-turbo-1106',
  ],
  AZURE_GATEWAY_MODEL_NAME: 'llmgateway-text-35turbo-model',
  AZURE_GATEWAY_MODEL_NAME_LIST: [
    'llmgateway-text-35turbo-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  COHERE_GATEWAY_MODEL_NAME: 'command-xlarge-nightly',
  COHERE_GATEWAY_MODEL_NAME_LIST: [
    'command-xlarge-nightly',
    'command',
    'command-light',
    'base',
    'base-light',
  ],

  GATEWAY_SUBSCRIPTION_NAME: 'ai-studio',
  GATEWAY_API_VERSION: '2023-05-15',

  OPENAI_MODEL_ID: 'gpt-3.5-turbo',
  OPENAI_MODEL_ID_LIST: [
    'gpt-4',
    'gpt-4-0613',
    'gpt-4-1106-preview',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-0613',
    'gpt-3.5-turbo-1106',
  ],
  OPENAI_ORG_ID: 'org-BZkhWvgPkvZuo3i8o08bsT34',
  OPENAI_KEY_ID: 'eselfridge@liveperson.com_1',

  COHERE_MODEL_ID: 'command-xlarge-nightly',
  COHERE_MODEL_ID_LIST: [
    'command-xlarge-nightly',
    'command',
    'command-light',
    'base',
    'base-light',
  ],
  GOOGLE_VERTEX_MODEL_ID: 'chat-bison',
  GOOGLE_VERTEX_MODEL_ID_LIST: ['chat-bison'],

  AZURE_MODEL_ID: 'llmgateway-text-35turbo-model',
  AZURE_MODEL_ID_LIST: [
    'llmgateway-text-35turbo-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  AZURE_API_BASE: 'https://adojo-openai-services.openai.azure.com/',
  AZURE_API_TYPE: 'azure',
  AZURE_API_VERSION: '2023-05-15',
  AZURE_KEY_ID: 'azure_1',

  FAST_CHAT_MODEL_ID: 'vicuna-7b',
  FAST_CHAT_MODEL_ID_LIST: ['vicuna-7b'],
  FAST_CHAT_API_BASE: 'https://lp-gai.int.liveperson.net/v1',
  FAST_CHAT_API_TYPE: 'open_ai',
  FAST_CHAT_API_VERSION: 'v1',
  FAST_CHAT_KEY_ID: 'fastchat_1',

  TGI_MODEL_ID: 'llama2-70b-8bit',
  TGI_MODEL_ID_LIST: ['llama2-70b-8bit'],
  TGI_API_BASE:
    'https://lp-model-inf.us-east1.prod.int.gw.lpcloud.io/openai/v1',
  TGI_API_TYPE: 'open_ai',
  TGI_API_VERSION: 'v1',
  TGI_KEY_ID: 'tgi_1',

  ANTHROPIC_MODEL_ID: 'claude-3-haiku-20240307',
  ANTHROPCIC_MODEL_ID_LIST: ['claude-3-haiku-20240307', 'claude-2.1'],
};

export const COMPLETIONS_DEFAULTS_US = {
  ...COMPLETIONS_DEFAULTS,
};

export const COMPLETIONS_DEFAULTS_EU = {
  ...COMPLETIONS_DEFAULTS,
  AZURE_GATEWAY_MODEL_NAME: 'llmgateway-text-35turbo-16k-model',
  AZURE_GATEWAY_MODEL_NAME_LIST: [
    'llmgateway-text-35turbo-1106-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  AZURE_MODEL_ID: 'llmgateway-text-35turbo-16k-model',
  AZURE_MODEL_ID_LIST: [
    'llmgateway-text-35turbo-1106-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  AZURE_KEY_ID: 'azure_eu',
  AZURE_API_BASE: 'https://lpaopa-p-aistudio-uk-1.openai.azure.com/',
  TGI_API_BASE:
    'https://lp-model-inf.europe-west1.prod.int.gw.lpcloud.io/openai/v1',
};

export const COMPLETIONS_DEFAULTS_AU = {
  ...COMPLETIONS_DEFAULTS,
  AZURE_GATEWAY_MODEL_NAME: 'llmgateway-text-35turbo-16k-model',
  AZURE_GATEWAY_MODEL_NAME_LIST: [
    'llmgateway-text-35turbo-1106-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  AZURE_MODEL_ID: 'llmgateway-text-35turbo-16k-model',
  AZURE_MODEL_ID_LIST: [
    'llmgateway-text-35turbo-1106-model',
    'llmgateway-text-35turbo-16k-model',
    'gpt-4',
  ],
  AZURE_KEY_ID: 'azure_au',
  AZURE_API_BASE: 'https://lpaopa-p-aistudio-au-2.openai.azure.com/',
  TGI_API_BASE:
    'https://lp-model-inf.australia-southeast1.prod.int.gw.lpcloud.io/openai/v1',
};

export const EMBEDDINGS_DEFAULTS = {
  OPENAI_GATEWAY_MODEL_NAME: 'text-embedding-ada-002',
  AZURE_GATEWAY_MODEL_NAME: 'aidojo-text-embedding-ada-model',
  COHERE_GATEWAY_MODEL_NAME: 'large',

  OPENAI_MODEL_ID: 'text-embedding-ada-002',
  OPENAI_ORG_ID: 'org-BZkhWvgPkvZuo3i8o08bsT34',
  OPENAI_KEY_ID: 'eselfridge@liveperson.com_1',

  COHERE_MODEL_ID: 'large',
  GOOGLE_VERTEX_MODEL_ID: 'textembedding-gecko',

  AZURE_MODEL_ID: 'aidojo-text-embedding-ada-model',
  AZURE_API_BASE: 'https://adojo-openai-services.openai.azure.com/',
  AZURE_API_TYPE: 'azure',
  AZURE_API_VERSION: '2022-12-01',
  AZURE_KEY_ID: 'azure_1',

  FAST_CHAT_MODEL_ID: 'vicuna-7b',
  FAST_CHAT_API_BASE: 'https://lp-gai.int.liveperson.net/v1',
  FAST_CHAT_API_TYPE: 'open_ai',
  FAST_CHAT_API_VERSION: null,
  FAST_CHAT_KEY_ID: 'fastchat_1',
};

export const EMBEDDINGS_DEFAULTS_US = {
  ...EMBEDDINGS_DEFAULTS,
};

export const EMBEDDINGS_DEFAULTS_EU = {
  ...EMBEDDINGS_DEFAULTS,
  AZURE_API_BASE: 'https://lpaopa-p-aistudio-uk-1.openai.azure.com/',
  AZURE_KEY_ID: 'azure_eu',
};

export const EMBEDDINGS_DEFAULTS_AU = {
  ...EMBEDDINGS_DEFAULTS,
  AZURE_API_BASE: 'https://lpaopa-p-aistudio-au-2.openai.azure.com/',
  AZURE_KEY_ID: 'azure_au',
};

/**
 * returns [env, zone, phase, dc, grid, ld client, firebase]
 */
export const initConfig = (lpEnv: LP_ENV): AppEnvVar => {
  switch (lpEnv) {
    case LP_ENV.LOCAL:
      return {
        env: LP_ENV.LOCAL,
        zone: 'z0',
        phase: 'DEFAULT',
        datacenter: 'tlv',
        grid: 'qa',
        ldClient: LD_CLIENT.QA,
        firebase: FIREBASE_CONF.DEV,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.QA:
      return {
        env: LP_ENV.QA,
        zone: 'z0',
        phase: 'DEFAULT',
        datacenter: 'tlv',
        grid: 'qa',
        ldClient: LD_CLIENT.QA,
        firebase: FIREBASE_CONF.DEV,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.ALPHA:
      return {
        env: LP_ENV.ALPHA,
        zone: 'z1-a',
        phase: 'DEFAULT',
        datacenter: 'va-a',
        grid: 'alpha',
        ldClient: LD_CLIENT.ALPHA,
        firebase: FIREBASE_CONF.ALPHA,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.PROD_US:
      return {
        env: LP_ENV.PROD_US,
        zone: 'z1',
        phase: 'DEFAULT',
        datacenter: 'va',
        grid: 'ga',
        ldClient: LD_CLIENT.PROD_US,
        firebase: FIREBASE_CONF.PROD_US,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.GCP_QA:
      return {
        env: LP_ENV.GCP_QA,
        zone: 'z0',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'qa',
        ldClient: LD_CLIENT.QA,
        firebase: FIREBASE_CONF.GCP_QA,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.GCP_ALPHA:
      return {
        env: LP_ENV.GCP_ALPHA,
        zone: 'z1-a',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'alpha',
        ldClient: LD_CLIENT.ALPHA,
        firebase: FIREBASE_CONF.GCP_ALPHA,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    case LP_ENV.GCP_PROD_AU:
      return {
        env: LP_ENV.GCP_PROD_AU,
        zone: 'australia-southeast1-atoc',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'prod',
        ldClient: LD_CLIENT.PROD_AU,
        firebase: FIREBASE_CONF.GCP_PROD_AU,
        completionsDefaults: COMPLETIONS_DEFAULTS_AU,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_AU,
      };
    case LP_ENV.GCP_PROD_EU:
      return {
        env: LP_ENV.GCP_PROD_EU,
        zone: 'europe-west1-btod',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'prod',
        ldClient: LD_CLIENT.PROD_EU,
        firebase: FIREBASE_CONF.GCP_PROD_EU,
        completionsDefaults: COMPLETIONS_DEFAULTS_EU,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_EU,
      };
    case LP_ENV.GCP_PROD_US:
      return {
        env: LP_ENV.GCP_PROD_US,
        zone: 'us-east1-btod',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'prod',
        ldClient: LD_CLIENT.PROD_US,
        firebase: FIREBASE_CONF.GCP_PROD_US,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
    default:
      return {
        env: LP_ENV.GCP_ALPHA,
        zone: 'z1-a',
        phase: 'DEFAULT',
        datacenter: 'gcp',
        grid: 'alpha',
        ldClient: LD_CLIENT.ALPHA,
        firebase: FIREBASE_CONF.GCP_ALPHA,
        completionsDefaults: COMPLETIONS_DEFAULTS_US,
        embeddingsDefaults: EMBEDDINGS_DEFAULTS_US,
      };
  }
};

/**
 *  hostname: { env, zone, phase, dc, grid, launchdarkly }
 */
export const HOST_MAP: Record<string, AppEnvVar> = {
  localhost: initConfig(LP_ENV.LOCAL),
  '127.0.0.1': initConfig(LP_ENV.LOCAL),
  '9cfe-116-255-35-184.ngrok-free.app': initConfig(LP_ENV.LOCAL),
  'localhost.dev.lprnd.net': initConfig(LP_ENV.LOCAL),
  'aidojo-qa.dev.lprnd.net': initConfig(LP_ENV.QA),
  'aidojo-qa.npr.lprnd.net': initConfig(LP_ENV.GCP_QA),
  'aidojo-a.liveperson.net': initConfig(LP_ENV.GCP_ALPHA),
  'aidojo-p-au.liveperson.net': initConfig(LP_ENV.GCP_PROD_AU),
  'aidojo-p-eu.liveperson.net': initConfig(LP_ENV.GCP_PROD_EU),
  'aidojo-p-us.liveperson.net': initConfig(LP_ENV.GCP_PROD_US),
  'aistudio-qa.npr.lprnd.net': initConfig(LP_ENV.GCP_QA),
  'aistudio-a.liveperson.net': initConfig(LP_ENV.GCP_ALPHA),
  'aistudio-alpha.liveperson.net': initConfig(LP_ENV.GCP_ALPHA),
  'aistudio-p-au.liveperson.net': initConfig(LP_ENV.GCP_PROD_AU),
  'aistudio-p-eu.liveperson.net': initConfig(LP_ENV.GCP_PROD_EU),
  'aistudio-p-us.liveperson.net': initConfig(LP_ENV.GCP_PROD_US),
  'aistudio-prod-au.liveperson.net': initConfig(LP_ENV.GCP_PROD_AU),
  'aistudio-prod-eu.liveperson.net': initConfig(LP_ENV.GCP_PROD_EU),
  'aistudio-prod-us.liveperson.net': initConfig(LP_ENV.GCP_PROD_US),
  // internal hostnames
  'lp-aidojo.qa.int.gw.lpcloud.io': initConfig(LP_ENV.GCP_QA),
  'lp-aidojo.alpha.int.gw.lpcloud.io': initConfig(LP_ENV.GCP_ALPHA),
  'lp-aistudio.qa.int.gw.lpcloud.io': initConfig(LP_ENV.GCP_QA),
  'lp-aistudio.alpha.int.gw.lpcloud.io': initConfig(LP_ENV.GCP_ALPHA),
  'lp-aistudio.australia-southeast1.prod.int.gw.lpcloud.io': initConfig(
    LP_ENV.GCP_PROD_AU,
  ),
  'lp-aistudio.us-east1.prod.int.gw.lpcloud.io': initConfig(LP_ENV.GCP_PROD_US),
  'lp-aistudio.europe-west1.prod.int.gw.lpcloud.io': initConfig(
    LP_ENV.GCP_PROD_EU,
  ),
};

export const DEFAULT_APP_ENV = initConfig(LP_ENV.GCP_QA);

export enum LD_FLAGS {
  APP_ENABLED = 'app.enabled',
  APP_DEBUG_ENABLED = 'app.debug.enabled',
  MAX_WORD_LIMIT = 'app.limit.words',
  AGENT_WIDGET_ENABLED = 'app.agentWidget.enabled',
  AGENT_WIDGET_DEBUGGER_ENABLED = 'app.agentWidget.debugger.enabled',
  KNOWLEDGEBASES_ENABLED = 'app.knowledgebases.enabled',
  KNOWLEDGEBASES_EXTERNAL_ENABLED = 'app.knowledgebases.external.enabled',
  KNOWLEDGEBASES_HEALTH_ENABLED = 'app.knowledgebases.health.enabled',
  KNOWLEDGEBASES_SOURCES_CONV_ENABLED = 'app.knowledgebases.sources.conversations.enabled',
  CONVERSATION_PLAYGROUND = 'app.conversationPlayground.enabled',
  SANDBOX_ENABLED = 'app.sandbox.enabled',
  FLOWS_ENABLED = 'app.flows.enabled',
  KAI_ENABLED = 'app.kai.enabled',
  CONNECTORS_EXTERNAL_ENABLED = 'app.connectors.external.enabled',
  CONNECTORS_ENABLED = 'app.connectors.enabled',
  CONNECTORS_TWILIO_ENABLED = 'app.connectors.twilio.enabled',
  EVALUATOR_RESOLUTION_ENABLED = 'app.evaluators.resolution.enabled',
  MODELS_LANGCHAIN_ACTION_AGENT_ENABLED = 'app.models.langchainActionAgent.enabled',
  CONVERSATIONAL_CLOUD_SSO_ENABLED = 'app.login.conversationalCloudSSO.enabled',
  MODELS_CONVERSATIONAL_CLOUD_ENABLED = 'app.models.conversationalCloud.enabled',
  FLOWS_PRIVILEGED_NODES_ENABLED = 'app.flows.privilegedNodes.enabled',
  FLOWS_USECASES_GUIDEDROUTING_ENABLED = 'app.flows.useCases.guidedRouting.enabled',
  FLOWS_ROUTE_GENERATION_ENABLED = 'app.flows.useCases.guidedInfo.routeGeneration.enabled',
  FLOWS_USECASES_GUIDEDINFO_ENABLED = 'app.flows.useCases.guidedInfo.enabled',
  FLOWS_USECASES_CONVERSATIONALFABRIC_ENABLED = 'app.flows.useCases.conversationalFabric.enabled',
  FLOWS_USECASES_CONVERSATIONALANALYSIS_ENABLED = 'app.flows.useCases.conversationalAnalysis.enabled',
  PERMISSIONS_CONV_VIEW_OTHERS_ENABLED = 'app.security.permissions.conversationViewOthers.enabled',
  FLOWS_VERSION = 'app.flows.version',
  TOOLBOX_TRANSCRIPT_ANALYSIS_ENABLED = 'app.toolbox.transcriptAnalysis.enabled',
  TTL_DAYS = 'app.ttl.days',
  CONVERSATION_CLOUD_CONVERSATION_SEARCH_ENABLED = 'app.conversationCloud.conversationSearch.enabled',
  TOOLBOX_SIMULATIONS_ENABLED = 'app.toolbox.simulations.enabled',
  BOTS_ENABLED = 'app.bots.enabled',
  BOTS_EXTERNAL_ENABLED = 'app.bots.external.enabled',
  SUMMARIES_ENABLED = 'app.summaries.enabled',
  SUMMARIES_EXTERNAL_ENABLED = 'app.summaries.external.enabled',
}

export enum ACTION_KEYS {
  GET_USER_DATA = 'GET_USER_DATA',
  GET_USERS = 'GET_USERS',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_MODELS = 'UPDATE_USER_MODELS',
  DELETE_USER = 'DELETE_USER',
  CREATE_CONVERSATION = 'CREATE_CONVERSATION',
  UPLOAD_CONVERSATION = 'UPLOAD_CONVERSATION',
  EXPORT_CONVERSATIONS = 'EXPORT_CONVERSATIONS',
  GET_CONVERSATION = 'GET_CONVERSATION',
  GET_CONVERSATIONS = 'GET_CONVERSATIONS',
  UPDATE_CONVERSATION = 'UPDATE_CONVERSATION',
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  DELETE_CONVERSATION = 'DELETE_CONVERSATION',
  GET_CONV_CLOUD_OPEN_CONVERSATIONS = 'GET_CONV_CLOUD_OPEN_CONVERSATIONS',
  CLOSE_CONV_CLOUD_CONVERSATION = 'CLOSE_CONV_CLOUD_CONVERSATION',
  CLOSE_FLOW_CONV_CLOUD_CONVERSATION = 'CLOSE_FLOW_CONV_CLOUD_CONVERSATION',
  PUT_SUGGESTION = 'PUT_SUGGESTION',
  GET_CATEGORIES = 'GET_CATEGORIES',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  GET_TRANSCRIPT_ANALYSES = 'GET_TRANSCRIPT_ANALYSES',
  GET_TRANSCRIPT_ANALYSIS = 'GET_TRANSCRIPT_ANALYSIS',
  POLL_TRANSCRIPT_ANALYSIS = 'POLL_TRANSCRIPT_ANALYSIS',
  CREATE_TRANSCRIPT_ANALYSIS = 'CREATE_TRANSCRIPT_ANALYSIS',
  UPDATE_TRANSCRIPT_ANALYSIS = 'UPDATE_TRANSCRIPT_ANALYSIS',
  DELETE_TRANSCRIPT_ANALYSIS = 'DELETE_TRANSCRIPT_ANALYSIS',
  GET_KNOWLEDGEBASES = 'GET_KNOWLEDGEBASES',
  CREATE_KNOWLEDGEBASE = 'CREATE_KNOWLEDGEBASE',
  UPDATE_KNOWLEDGEBASE = 'UPDATE_KNOWLEDGEBASE',
  DELETE_KNOWLEDGEBASE = 'DELETE_KNOWLEDGEBASE',
  GET_KNOWLEDGEBASE_FILE = 'GET_KNOWLEDGEBASE_FILE',
  GET_KNOWLEDGEBASE_ITEMS = 'GET_KNOWLEDGEBASE_ITEMS',
  CREATE_KNOWLEDGEBASE_ITEMS = 'CREATE_KNOWLEDGEBASE_ITEMS',
  UPDATE_KNOWLEDGEBASE_ITEMS = 'UPDATE_KNOWLEDGEBASE_ITEMS',
  DELETE_KNOWLEDGEBASE_ITEMS = 'DELETE_KNOWLEDGEBASE_ITEMS',
  GET_KAI_KNOWLEDGEBASES = 'GET_KAI_KNOWLEDGEBASES',
  CALCULATE_KNOWLEDGEBASE_HEALTH = 'CALCULATE_KNOWLEDGEBASE_HEALTH',
  GET_SIMULATION = 'GET_SIMULATION',
  GET_SIMULATION_STATUS = 'GET_SIMULATION_STATUS',
  GET_SIMULATION_RESULTS = 'GET_SIMULATION_RESULTS',
  GET_SIMULATIONS = 'GET_SIMULATIONS',
  CREATE_SIMULATION = 'CREATE_SIMULATION',
  UPDATE_SIMULATION = 'UPDATE_SIMULATION',
  DELETE_SIMULATION = 'DELETE_SIMULATION',
  GET_RESOLUTION_EVALUATION = 'GET_RESOLUTION_EVALUATION',
  GENERATE_QUESTION_KB_RANDOM = 'GENERATE_QUESTION_KB_RANDOM',
  GENERATE_QUESTION_SIM = 'GENERATE_QUESTION_SIM',
  GENERATE_KAI_ROUTES = 'GENERATE_KAI_ROUTES',
  GET_GENERATE_KAI_ROUTES_STATUS = 'GET_GENERATE_KAI_ROUTES_STATUS',
  CREATE_MODEL = 'CREATE_MODEL',
  UPDATE_MODEL = 'UPDATE_MODEL',
  DELETE_MODEL = 'DELETE_MODEL',
  GET_MODELS = 'GET_MODELS',
  SELECT_MODEL = 'SELECT_MODEL',
  SEND_MESSAGE = 'SEND_MESSAGE',
  GET_SUGGESTION = 'GET_SUGGESTION',
  CREATE_CONVERSATION_SUMMARY = 'CREATE_CONVERSATION_SUMMARY',
  CREATE_BATCH_SUMMARY = 'CREATE_BATCH_SUMMARY',
  CREATE_CONVERSATION_QUERY = 'CREATE_CONVERSATION_QUERY',
  GET_BATCH_SUMMARIES = 'GET_BATCH_SUMMARIES',
  GET_BATCH_SUMMARY = 'GET_BATCH_SUMMARY',
  DELETE_BATCH_SUMMARIES = 'DELETE_BATCH_SUMMARIES',
  UPLOAD_KNOWLEDGEBASE = 'UPLOAD_KNOWLEDGEBASE',
  UPLOAD_STATUS = 'UPLOAD_STATUS',
  GET_KNOWLEDGEBASE = 'GET_KNOWLEDGEBASE',
  PROCESSOR_CONVERSATION_PROVIDER = 'PROCESSOR_CONVERSATION_PROVIDER',
  PROCESSOR_CONTEXTUALIZER = 'PROCESSOR_CONTEXTUALIZER',
  PROCESSOR_SEARCHER = 'PROCESSOR_SEARCHER',
  PROCESSOR_PROMPT_MANAGER = 'PROCESSOR_PROMPT_MANAGER',
  PROCESSOR_PROMPT_ASSEMBLER = 'PROCESSOR_PROMPT_ASSEMBLER',
  PROCESSOR_LLM_RESPONDER = 'PROCESSOR_LLM_RESPONDER',
  PROCESSOR_POST_PROCESSOR = 'PROCESSOR_POST_PROCESSOR',
  GET_CONNECTORS = 'GET_CONNECTORS',
  CREATE_CONNECTOR = 'CREATE_CONNECTOR',
  UPDATE_CONNECTOR = 'UPDATE_CONNECTOR',
  DELETE_CONNECTOR = 'DELETE_CONNECTOR',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  GET_FLOWS = 'GET_FLOWS',
  GET_FLOW = 'GET_FLOW',
  CREATE_FLOW = 'CREATE_FLOW',
  UPDATE_FLOW = 'UPDATE_FLOW',
  DELETE_FLOW = 'DELETE_FLOW',
  VALIDATE_FLOW = 'VALIDATE_FLOW',
  GET_FLOW_NODE_TEMPLATES = 'GET_FLOW_NODE_TEMPLATES',
  GET_FLOW_USE_CASE_TEMPLATES = 'GET_FLOW_USE_CASE_TEMPLATES',
  GET_FLOW_CB_TEMPLATE = 'GET_FLOW_CB_TEMPLATE',
  TEST_FLOW = 'TEST_FLOW',
  SSO_LOGIN = 'SSO_LOGIN',
  SSO_LOGOUT = 'SSO_LOGOUT',
  EVALUATE_GUIDED_ROUTING = 'EVALUATE_GUIDED_ROUTING',
  GENERATE_QUESTION_CONV_CLOUD = 'GENERATE_QUESTION_CONV_CLOUD',
  GET_LIB_PROMPTS_ACCOUNT = 'GET_LIB_PROMPTS_ACCOUNT',
  GET_LIB_PROMPTS_SYSTEM = 'GET_LIB_PROMPTS_SYSTEM',
  GET_LIB_PROMPTS_LLM_PROVIDERS = 'GET_LIB_PROMPTS_LLM_PROVIDERS',
  CREATE_LIB_PROMPT = 'CREATE_LIB_PROMPT',
  UPDATE_LIB_PROMPT = 'UPDATE_LIB_PROMPT',
}

export enum MESSAGE_RATING {
  NEGATIVE = -1,
  POSITIVE = 1,
}

export enum EXPORT_TYPE {
  CSV,
  PNG,
  PDF,
}

export const COLORS: IColor[] = [
  {
    light: '#0030B2',
    dark: '#9CB2F3',
  },
  {
    light: '#B42318',
    dark: '#F8EDF0',
  },
  {
    light: '#027A48',
    dark: '#47C78C',
  },
  {
    light: '#B54708',
    dark: '#FEDF89',
  },
  {
    light: '#5925DC',
    dark: '#D9D6FE',
  },
  {
    light: '#B93815',
    dark: '#F9DBAF',
  },
  {
    light: '#026AA2',
    dark: '#B9E6FE',
  },
  {
    light: '#363F72',
    dark: '#D5D9EB',
  },
];

export enum CONVERSATION_SOURCE {
  AI_DOJO = 'AI_DOJO',
  CONVERSATIONAL_CLOUD = 'CONVERSATIONAL_CLOUD',
  SIMULATION = 'SIMULATION',
  UNKNOWN = 'UNKNOWN',
  WHATSAPP = 'WHATSAPP',
  SMS = 'SMS',
}

export enum CONVERSATION_STATE {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const UNKNOWN_SOURCE = {
  MODEL_DESC: 'Conversation was generated by via an unknown source',
  MODEL_NAME: 'Unknown origin',
  MODEL_ID: CONVERSATION_SOURCE.UNKNOWN,
  CATEGORY_DESC: 'Conversation is associated with an unknown source',
  CATEGORY_NAME: 'Unknown source',
  CATEGORY_ID: CONVERSATION_SOURCE.UNKNOWN,
};

export enum FILTER_QUERY_TYPE {
  ALL = 'all',
  PRIVATE = 'private',
}

export enum FILTER_OWNER {
  ALL = 'all',
  SELF = 'self',
}

export enum FILTER_CREATED_BY {
  ANY = 'any',
  SELF = 'self',
}

export enum FILTER_DIRECTION {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export enum FORM_TYPE {
  TEXT_FIELD = 'text-field',
  TEXTAREA = 'textarea',
  SWITCH = 'switch',
  SELECT = 'select',
  COMBO = 'combo',
  DIVIDER = 'divider',
  FILE_INPUT = 'file-input',
  JSON_EDITOR = 'json-editor',
  RADIO = 'radio',
}

export const DIVIDER_KEY = 'DIVIDER_KEY';

export const FORM_COUNTERS = {
  account_sid: 34,
  display_name: 35,
  display_description: 600,
  kb_item: 30720,
  short: 50,
  medium: 100,
  long: 500,
  json: 4096,
};

const required = (v: any) => {
  return (typeof v === 'number' && !isNaN(v)) || !!v || 'Field is required';
};

export type RuleTypes =
  | 'required'
  | 'notEmpty'
  | 'display_name'
  | 'baseQuery'
  | 'display_description'
  | 'short'
  | 'medium'
  | 'long'
  | 'json'
  | 'number'
  | 'kb_item'
  | 'number_over_zero'
  | 'number_200'
  | 'number_1000'
  | 'account_sid';

export const FORM_RULES: Record<RuleTypes, any> = {
  required,
  baseQuery: (v: any) => {
    const value = v?.title ?? v ?? '';
    if (!value) return 'Field is required';
    return (
      value.length <= FORM_COUNTERS.medium ||
      `Must be less than ${FORM_COUNTERS.medium} characters`
    );
  },
  number: (v: any) => !isNaN(v) || 'Must be a number',
  number_over_zero: (v: any) => v > 0 || 'Must be greater than 0',
  number_200: (v: any) => (v > 0 && v <= 200) || 'Must be > 0 and <= 200',
  number_1000: (v: any) => (v > 0 && v <= 1000) || 'Must be > 0 and <= 1000',
  notEmpty: (v: string | any[]) =>
    String(v || '').length > 0 || `Must not be empty`,
  display_name: (v: any) =>
    (v || '').length <= FORM_COUNTERS.display_name ||
    `Must be less than ${FORM_COUNTERS.display_name} characters`,
  display_description: (v: any) =>
    (v || '').length <= FORM_COUNTERS.display_description ||
    `Must be less than ${FORM_COUNTERS.display_description} characters`,
  kb_item: (v: any) =>
    (v || '').length <= FORM_COUNTERS.kb_item ||
    `Must be less than ${FORM_COUNTERS.kb_item} characters`,
  account_sid: (v: any) =>
    (v || '').length === FORM_COUNTERS.account_sid ||
    `Must be equal to ${FORM_COUNTERS.account_sid} characters`,
  short: (v: any) =>
    (v || '').length <= FORM_COUNTERS.short ||
    `Must be less than ${FORM_COUNTERS.short} characters`,
  medium: (v: any) =>
    (v || '').length <= FORM_COUNTERS.medium ||
    `Must be less than ${FORM_COUNTERS.medium} characters`,
  long: (v: any) =>
    (v || '').length <= FORM_COUNTERS.long ||
    `Must be less than ${FORM_COUNTERS.long} characters`,
  json: (v: any) => {
    try {
      return !!JSON.parse(v || '{}');
    } catch (err) {
      return `Invalid JSON detected`;
    }
  },
};

export enum CONVERSATION_ASIDE_TABS {
  EXPLORE = 'EXPLORE',
  SETTINGS = 'SETTINGS',
  DEBUG = 'DEBUG',
}

export enum CONVERSATION_NAVIGATOR_TABS {
  BOTS = 'BOTS',
  FLOWS = 'FLOWS',
}

export enum STATUS {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export const SUMMARY_BATCH_LIMIT = 1000;

export enum FORM_MODE {
  CREATE,
  UPDATE,
  COPY,
}

export enum MODEL_FILTER_TYPE {
  MY_MODELS = 'MY_MODELS',
  ALL_MODELS = 'ALL_MODELS',
  FAV_MODELS = 'FAV_MODELS',
}

export const MODEL_FILTER_ITEMS = [
  { type: 'item', title: 'All Bots', value: MODEL_FILTER_TYPE.ALL_MODELS },
  { type: 'item', title: 'Favorite Bots', value: MODEL_FILTER_TYPE.FAV_MODELS },
  { type: 'item', title: 'My Bots', value: MODEL_FILTER_TYPE.MY_MODELS },
];

export enum VIEW_MODE {
  BASIC,
  ADVANCED,
}

export enum KNOWLEDGEBASE_MODE {
  LEGACY = 'LEGACY',
  KNOWLEDGEBASES = 'KNOWLEDGEBASES',
  KAI = 'KAI',
}

export enum PROMPT_STYLE {
  LESS = 'less',
  TRUTH = 'truth',
  TRUTH_CONCISE = 'truth_concise',
  SALES = 'sales',
  FREE = 'free',
  MORE = 'more',
  FREE_DEMO = 'free_demo',
  DETAIL_DEMO = 'detail_demo',
  CONVO = 'convo',
  SIM = 'sim',
  CUSTOM = 'custom',
}

export const PROMPT_STYLES = Object.entries(PROMPT_STYLE).map(([, val]) => val);

export enum CONTEXT_STYLE {
  CONVERSATION = 'convo',
  SIM = 'sim',
  QA = 'QA',
}

export const CONTEXT_STYLES = Object.entries(CONTEXT_STYLE).map(
  ([, val]) => val,
);

export enum HALLUCINATION_DETECTION {
  ON = 'on',
  OFF = 'off',
}

export enum AGENT_WIDGET_DATA_PATHS {
  CHAT_INFO = 'chatInfo',
  CHAT_TRANSCRIPT = 'chatTranscript',
}

export enum AGENT_WIDGET_SOURCE {
  VISITOR = 'visitor',
  AGENT = 'agent',
  SYSTEM = 'system',
}

export enum AGENT_WIDGET_STATUS {
  CLOSED = 'CLOSE',
  OPEN = 'OPEN',
}

export enum MODEL_SUGGESTION_STATUS {
  ERROR,
  LOADING,
  COMPLETE,
}

export enum COMPLETIONS_PROVIDER {
  OPEN_AI = 'OPEN_AI',
  COHERE = 'COHERE',
  AZURE = 'AZURE',
  GOOGLE = 'GOOGLE',
  FAST_CHAT = 'FAST_CHAT',
  TGI = 'TGI',
  ANTHROPIC = 'ANTHROPIC',
  OPEN_AI_GATEWAY = 'OPEN_AI_GATEWAY',
  COHERE_GATEWAY = 'COHERE_GATEWAY',
  AZURE_GATEWAY = 'AZURE_GATEWAY',
}

export enum EMBEDDINGS_PROVIDER {
  OPEN_AI = 'OPEN_AI',
  COHERE = 'COHERE',
  AZURE = 'AZURE',
  GOOGLE = 'GOOGLE',
  FAST_CHAT = 'FAST_CHAT',
  TGI = 'TGI',
  ANTHROPIC = 'ANTHROPIC',
  OPEN_AI_GATEWAY = 'OPEN_AI_GATEWAY',
  COHERE_GATEWAY = 'COHERE_GATEWAY',
  AZURE_GATEWAY = 'AZURE_GATEWAY',
}

export const EMBEDDINGS_PROVIDER_MAP: Record<
  COMPLETIONS_PROVIDER,
  EMBEDDINGS_PROVIDER
> = {
  [COMPLETIONS_PROVIDER.OPEN_AI]: EMBEDDINGS_PROVIDER.OPEN_AI,
  [COMPLETIONS_PROVIDER.COHERE]: EMBEDDINGS_PROVIDER.COHERE,
  [COMPLETIONS_PROVIDER.AZURE]: EMBEDDINGS_PROVIDER.AZURE,
  [COMPLETIONS_PROVIDER.GOOGLE]: EMBEDDINGS_PROVIDER.GOOGLE,
  [COMPLETIONS_PROVIDER.FAST_CHAT]: EMBEDDINGS_PROVIDER.FAST_CHAT,
  [COMPLETIONS_PROVIDER.TGI]: EMBEDDINGS_PROVIDER.TGI,
  [COMPLETIONS_PROVIDER.ANTHROPIC]: EMBEDDINGS_PROVIDER.ANTHROPIC,
  [COMPLETIONS_PROVIDER.OPEN_AI_GATEWAY]: EMBEDDINGS_PROVIDER.OPEN_AI_GATEWAY,
  [COMPLETIONS_PROVIDER.AZURE_GATEWAY]: EMBEDDINGS_PROVIDER.AZURE_GATEWAY,
  [COMPLETIONS_PROVIDER.COHERE_GATEWAY]: EMBEDDINGS_PROVIDER.COHERE_GATEWAY,
};

export const COMPLETIONS_PROVIDERS = Object.entries(COMPLETIONS_PROVIDER).map(
  ([, val]) => val,
);
export const EMBEDDINGS_PROVIDERS = Object.entries(EMBEDDINGS_PROVIDER).map(
  ([, val]) => val,
);

export enum KNOWLEDGEBASE_SOURCE {
  FILE_UPLOAD = 'FILE_UPLOAD',
  TEXT = 'TEXT',
  CONVERSATIONS = 'CONVERSATIONS',
}

export enum FILE_EXTENSION {
  JSON = 'json',
  CSV = 'csv',
  PDF = 'pdf',
}

export enum FILE_TYPE {
  JSON = 'application/json',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}
export const FILE_TYPES = Object.entries(FILE_TYPE).map(([, val]) => val);

export enum PROCESSORS {
  CONVERSATION_PROVIDER = 'conversation_provider',
  CONTEXTUALIZER = 'contextualizer',
  SEARCHER = 'searcher',
  PROMPT_MANAGER = 'prompt_manager',
  PROMPT_ASSEMBLER = 'prompt_assembler',
  LLM_RESPONDER = 'llm_responder',
  POST_PROCESSOR = 'post_processor',
}

export enum LEVEL_OF_DETAIL {
  STANDARD = 'standard',
  VERBOSE = 'verbose',
}

export const LEVEL_OF_DETAILS = Object.entries(LEVEL_OF_DETAIL).map(
  ([, val]) => val,
);

export enum KAI_SEARCH_MODE {
  KAI_SEARCH = 'intents',
  INTENTS_ONLY = 'intents_only',
  AI_SEARCH_ONLY = 'all',
}

export const KAI_SEARCH_MODES = Object.entries(KAI_SEARCH_MODE).map(
  ([key, val]) => ({ title: key, value: val }),
);

export enum KAI_CONFIDENCE_LEVEL {
  VERY_GOOD = 'vg',
  GOOD = 'g',
  FAIR_PLUS = 'fp',
  FAIR = 'f',
  POOR = 'p',
}

export const KAI_CONFIDENCE_LEVELS = Object.entries(KAI_CONFIDENCE_LEVEL).map(
  ([key, val]) => ({ title: key, value: val }),
);

export const KAI_NUM_RESULTS = [...Array(5).keys()].map((x) => x + 1);
export enum SUMMARY_BATCH_SOURCE {
  AI_DOJO = 'AI_DOJO',
  CONVERSATIONAL_CLOUD = 'CONVERSATIONAL_CLOUD',
}

export enum KB_CONV_SEARCH_SOURCE {
  AI_DOJO = 'AI_DOJO',
  CONVERSATIONAL_CLOUD = 'CONVERSATIONAL_CLOUD',
}

export enum FILTER_DIALOG_OPTION_TYPE {
  SELECT = 'SELECT',
  TEXT_FIELD = 'TEXT_FIELD',
  NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX',
}

export enum SUMMARY_BATCH_FILTER_KEY {
  ACCOUNT_ID = 'brandId',
  CATEGORY_ID = 'category_id',
  MODEL_ID = 'model_id',
  MCS_STANDARD = 'mcs_standard',
  SKILL_IDS = 'skill_ids',
  DAYS = 'days',
  CONSUMER_KEYWORDS = 'consumer_keywords',
  AGENT_KEYWORDS = 'agent_keywords',
}

export enum KB_CONV_SEARCH_FILTER_KEY {
  LIMIT = 'limit',
  SOURCE = 'source',
  ACCOUNT_ID = 'account_id',
  CATEGORY_ID = 'category_id',
  MODEL_ID = 'model_id',
  MCS_STANDARD = 'mcs_standard',
  SKILL_IDS = 'skill_ids',
  DAYS = 'days',
  CONSUMER_KEYWORDS = 'consumer_keywords',
  AGENT_KEYWORDS = 'agent_keywords',
}

export enum CONVERSATION_FILTER_KEY {
  ACCOUNT_ID = 'account_id',
  MODEL_ID = 'model_id',
  START = 'start',
  END = 'end',
  CONVERSATION_ID = 'conversation_id',
  SOURCE = 'source',
  QUERY_TYPE = 'query_type',
  CUSTOM_LABEL = 'custom_label',
  INCLUDE_MESSAGES = 'include_messages',
  INCLUDE_SUGGESTIONS = 'include_suggestions',
  HAS_FEEDBACK = 'has_feedback',
  HAS_SUGGESTIONS = 'has_suggestions',
}

export enum MCS_STANDARD {
  POSITIVE = 100,
  NEUTRAL = 0,
  NEGATIVE = -100,
}

export enum CONNECTOR_TYPE {
  TWILIO = 'TWILIO',
}

export const CONNECTOR_TYPES = Object.entries(CONNECTOR_TYPE).map(
  ([, val]) => val,
);

export enum CODE_EDITOR_TYPE {
  TEXT = 'text',
  JSON = 'json',
  JSON_SCHEMA = 'json_schema',
  PYTHON = 'python',
  JAVASCRIPT = 'javascript',
}

export const CODE_EDITOR_TITLES: { [p in CODE_EDITOR_TYPE]: string } = {
  [CODE_EDITOR_TYPE.TEXT]: 'Text',
  [CODE_EDITOR_TYPE.JSON]: 'JSON',
  [CODE_EDITOR_TYPE.JSON_SCHEMA]: 'JSON Schema',
  [CODE_EDITOR_TYPE.PYTHON]: 'Python',
  [CODE_EDITOR_TYPE.JAVASCRIPT]: 'Javascript',
};

export enum KB_HEALTH_OVERRIDES {
  HEALTHY = 'healthy',
  UNHEALTHY = 'unhealthy',
  NONE = 'none',
}

export enum KB_HEALTH_TYPES {
  ROUGE = 'rouge_score',
  MEDIAN_LINE_LENGTH = 'median_line_length',
  EMBEDDING_DOT_SIMILARITY = 'embedding_dot_similarity_score',
  LLM_SIMILARITY = 'llm_similarity_score',
  LLM_OVERLAP = 'llm_overalap_score',
  BLEU = 'bleu_score',
  METEOR = 'meteor_score',
  PII_PRESENCE = 'pii_presence_score',
}

export const KB_HEALTH_THRESHOLD = 0.7;

export const PERSONAS = [
  {
    title: 'Friendly and approachable',
    value:
      'This persona style is characterized by warm, welcoming, and polite language. The AI bot interacts in a manner that makes users feel at ease and encourages open communication',
  },
  {
    title: 'Professional and informative',
    value:
      'This persona focuses on providing accurate and useful information in a clear, concise manner. The AI bot uses a more formal tone and avoids using slang or colloquial expressions',
  },
  {
    title: 'Humorous and witty',
    value:
      "This persona employs clever wordplay, puns, and jokes to engage users and create an enjoyable conversational experience. The AI bot's language is light-hearted and entertaining, yet still able to deliver relevant information",
  },
  {
    title: 'Empathetic and supportive',
    value:
      "This persona exhibits understanding and compassion, offering emotional support and encouragement in conversations. The AI bot uses language that conveys caring and validation of the user's feelings or concerns",
  },
  {
    title: 'Casual and conversational',
    value:
      'This persona adopts a casual and informal tone, using everyday language and colloquialisms. The AI bot engages users as if it were a friend, fostering a relaxed and relatable interaction',
  },
  {
    title: 'Task-oriented and efficient',
    value:
      'This persona is focused on helping users achieve specific goals or complete tasks quickly. The AI bot provides clear instructions and relevant information in a straightforward and concise manner.',
  },
  {
    title: 'Authoritative and knowledgeable',
    value:
      'This persona displays a high level of expertise and understanding in a particular subject area. The AI bot uses a confident tone and provides well-researched, dependable information to users',
  },
  {
    title: 'Youthful and trendy',
    value:
      'This persona incorporates slang, popular culture references, and a more energetic and casual style of communication. The AI bot appeals to a younger audience by engaging with the latest trends and staying up-to-date with current events.',
  },
  {
    title: 'Noble steed',
    value: 'The witty and charmful persona of a 16th century noble scotsman',
  },
  {
    title: 'Nurturing and coaching',
    value:
      'The AI bot acts as a mentor or guide, working to motivate and inspire users to achieve personal growth and goals. This persona uses encouraging language and offers practical advice and solutions.',
  },
  {
    title: 'Creative and imaginative',
    value:
      'This persona uses vivid descriptive language, metaphors, and storytelling to engage users and spark their imagination. The AI bot provides information in a more unique and artistic manner, leaving room for interpretation and deeper thought.',
  },
  {
    title: 'Sassy and confident',
    value:
      'This persona showcases a bold, opinionated, and sometimes sarcastic tone. The AI bot is unapologetically self-assured, offering blunt and honest advice or insights',
  },
  {
    title: 'Neutral and objective',
    value:
      'This persona focuses on providing factual information without bias or personal opinions. The AI bot uses a neutral tone and avoids expressing emotions or preferences.',
  },
];
